<template>
	<!-- eslint-disable-next-line vue/no-v-html -->
	<div :class="['cog-content', 'cog-html', 'cog-input', { 'cog-force-shown': forceShown}]" v-html="markup"></div>
</template>

<script>
	import { isSourceAdapter } from '@cognitoforms/vuemodel';
	import { escapeHtml } from 'src/util/helpers';

	export default {
		name: 'CContent',
		inject: ['$resource'],
		props: {
			content: { type: String, required: true },
			raw: { type: Boolean, default: false },
			forceShown: { type: Boolean, default: false }
		},
		computed: {
			entity() {
				for (let parent = this.$parent, parentLevel = 1; parent != null; parent = parent.$parent, parentLevel += 1)
					if (isSourceAdapter(parent.$source))
						return parent.$source.value;
				return null;
			},
			markup() {
				return this.formatText(this.raw || !this.entity ? this.content : this.entity.toString(this.content, value => this.wrapDataUriWithImg(value)));
			}
		},
		methods: {
			formatText(value) {
				// Note: Equivalent of `Cognito.formatText` in v1 forms
				return value ? value.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\r/g, '<br/>') : value;
			},
			wrapDataUriWithImg(value) {
				if (!value || typeof value !== 'string')
					return value;
				else if (value.startsWith('data:image'))
					return `<img src="${escapeHtml(value)}" alt="${this.$resource('element-label-signature')}" />`;
				else
					return escapeHtml(value);
			}
		}
	};
</script>

<style lang="scss">
#{$specificity-base} {

	.cog-content pre {
		white-space: pre-wrap;
	}
}
</style>