var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    class: [
      "cog-content",
      "cog-html",
      "cog-input",
      { "cog-force-shown": _vm.forceShown }
    ],
    domProps: { innerHTML: _vm._s(_vm.markup) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }